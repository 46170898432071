//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Carousel, Slide } from '@fc/angie-ui';
import FCImage from '~/components/FCImage.vue';

export default {
  name: 'CommunityMobileCarousel',
  components: {
    Carousel,
    Slide,
    FCImage,
  },
  props: {
    communityList: {
      type: Array,
      required: true,
      validator(data) {
        return data.every(entry => entry.src && entry.alt && entry.instagramHandle);
      },
    },
  },
};
